import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29')
];

export const server_loads = [0,8,2];

export const dictionary = {
		"/(public)": [23,[8]],
		"/(private)/create": [17,[2]],
		"/(private)/create/[apptypeId]": [18,[2]],
		"/(public)/login": [~24,[8]],
		"/(public)/login/email": [25,[8]],
		"/(public)/login/forgotten-password": [26,[8]],
		"/(public)/login/forgotten-password/password": [27,[8]],
		"/(public)/signup": [28,[8]],
		"/(public)/signup/password": [29,[8]],
		"/(private)/user/settings/git": [19,[2,5]],
		"/(private)/vps/new": [22,[2]],
		"/(private)/vps/[vpsId]": [20,[2,6]],
		"/(private)/vps/[vpsId]/settings/general": [21,[2,6,7]],
		"/(private)/[apptypeId]/[appId]": [9,[2,3]],
		"/(private)/[apptypeId]/[appId]/deployments": [10,[2,3]],
		"/(private)/[apptypeId]/[appId]/deployments/[deploymentId]": [11,[2,3]],
		"/(private)/[apptypeId]/[appId]/logs": [12,[2,3]],
		"/(private)/[apptypeId]/[appId]/settings/envs": [13,[2,3,4]],
		"/(private)/[apptypeId]/[appId]/settings/general": [14,[2,3,4]],
		"/(private)/[apptypeId]/[appId]/settings/git": [15,[2,3,4]],
		"/(private)/[apptypeId]/[appId]/settings/logs": [16,[2,3,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';